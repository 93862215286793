import type { ISbLinkField } from '~ui/types';

export function getInternalLink(linkObject: ISbLinkField) {
    if (!linkObject) return;
    if (linkObject.email) {
        return `mailto:${linkObject.email}`;
    }
    // Constant variable for access to Runtime Config
    const config = useRuntimeConfig();
    let link: string = linkObject.url || linkObject.cached_url || '';
    if (link) {
        if (link?.startsWith('https://airportparkingreservations.com')) {
            // @ts-ignore
            return link.replace('https://airportparkingreservations.com', config?.public.SITE_URL);
        }

        if (link.includes('/airports/')) {
            if (link.includes('/hotel-parking')) {
                if (link.includes('/port')) {
                    link = link.replace('/airports/hotel-parking/port/', '') + '/port-hotels-with-parking';
                } else {
                    link = link.replace('/airports/hotel-parking/', '') + '/airport-hotels-with-parking';
                }
            }
            if (link.includes('/parking-coupons')) {
                link = link.replace('/airports/parking-coupons/', '') + '/parking-coupons';
            } else if (link.includes('/parking')) {
                if (link.includes('/port')) {
                    link = link.replace('/airports/parking/port/', '') + '/port-parking';
                } else {
                    link = link.replace('/airports/parking/', '') + '/airport-parking';
                }
            }
            if (link.includes('/shuttles')) {
                link = link.replace('/airports/shuttles/', '') + '/airport-shuttles';
            }
            if (link.includes('/guides')) {
                link = link.replace('/airports/guides/', '');
            }
        }
        link = link.toLowerCase().replace(`${config?.public.STORYBLOK_FOLDER}/pages`, '');

        link =
            link[0] === '/' || link[0] === '#' || link.includes('http') || link.includes('mailto:') ? link : `/${link}`;

        link =
            link[0] != '#' && !link.includes('http') && !link.includes('mailto:')
                ? config?.public.SITE_URL + link
                : link;
        return link;
    }
    return '/';
}
